<template>
  <div class="nav_wrap" :class="{ nav_wrap_isTop: isTop }">
    <el-affix :offset="0">
      <div
        class="menu_box"
        :class="{
          menu_box_bg_black: !isLucency && isBlack,
          menu_box_bg_white: !isLucency && isWhite,
          menu_box_bg_transparent: isLucency,
        }"
      >
        <div class="left_box">
          <div
            class="logo_box"
            :class="{
              logo_box_black: !isLucency && isBlack,
              logo_box_white: !isLucency && isWhite,
              logo_box_transparent: isLucency,
            }"
            @click="logoClick()"
          ></div>

          <el-menu
            :default-active="activeIndex"
            class="el_menu_demo"
            :class="{
              el_menu_demo_black: !isLucency && isBlack,
              el_menu_demo_white: !isLucency && isWhite,
              el_menu_demo_transparent: isLucency,
            }"
            mode="horizontal"
            :ellipsis="false"
            @select="handleSelect"
          >
            <!-- <el-menu-item index="/">首页</el-menu-item>
            <el-menu-item index="/aboutUs">关于我们</el-menu-item>
            <el-menu-item index="/joinUs">加入我们</el-menu-item>
            <el-menu-item index="/yxStudy">益学研究苑</el-menu-item>
            <el-menu-item index="/product">产品中心</el-menu-item>
            <el-menu-item index="/vip">会员中心</el-menu-item> -->

            <el-menu-item
              v-for="(item, index) in menuList"
              :key="index"
              :index="item.index"
            >
              {{ item.name }}
            </el-menu-item>

            <!-- <el-sub-menu index="2">
          <template #title>Workspace</template>
          <el-menu-item index="2-1">item one</el-menu-item>
          <el-menu-item index="2-2">item two</el-menu-item>
          <el-menu-item index="2-3">item three</el-menu-item>
          <el-sub-menu index="2-4">
            <template #title>item four</template>
            <el-menu-item index="2-4-1">item one</el-menu-item>
            <el-menu-item index="2-4-2">item two</el-menu-item>
            <el-menu-item index="2-4-3">item three</el-menu-item>
          </el-sub-menu>
        </el-sub-menu> -->
          </el-menu>
        </div>

        <div
          class="right_login"
          :class="{
            right_login_black: !isLucency && isBlack,
            right_login_white: !isLucency && isWhite,
            right_login_transparent: isLucency,
          }"
        >
          <div class="login_text" v-if="!isLogin" @click="loginClick()">
            登录
          </div>

          <div class="login_photo" v-else>
            <el-tooltip
              placement="bottom"
              effect="customized"
              @show="handleTooltipShow"
            >
              <template #content>
                <div class="login_content">
                  <div class="msg_box">
                    <div class="info_box">
                      <img
                        class="photo_info"
                        :src="
                          userInfo.userPhoto
                            ? userInfo.userPhoto.indexOf('http') != -1
                              ? userInfo.userPhoto.replace(/^https?:/, '')
                              : '//sf.yxcps.cn' + userInfo.userPhoto
                            : require('@/assets/home/default_avatar.png')
                        "
                      />

                      <div class="info">
                        <div class="name">{{ userInfo.userName }}</div>
                        <div class="yx">
                          ID:YX{{ userInfo.userNo }}

                          <img
                            src="@/assets/home/icon4.png"
                            class="copy_icon"
                            :data-clipboard-text="'yx' + userInfo.userNo"
                            @click="copyClick()"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="logout">
                      <span @click="logoutClick()">退出登录</span>
                    </div>
                  </div>

                  <div class="menu_box">
                    <div class="menu" @click="menuClick(1)">
                      <img src="@/assets/home/icon1.png" />
                      账号设置
                    </div>

                    <div class="menu" @click="menuClick(2)">
                      <img src="@/assets/home/icon2.png" />
                      我的订单
                    </div>

                    <div class="menu" @click="menuClick(3)">
                      <img src="@/assets/home/icon3.png" />
                      我的高级课
                    </div>
                  </div>
                </div>
              </template>

              <img
                class="photo"
                :src="
                  userInfo.userPhoto
                    ? userInfo.userPhoto.indexOf('http') != -1
                      ? userInfo.userPhoto.replace(/^https?:/, '')
                      : '//sf.yxcps.cn' + userInfo.userPhoto
                    : require('@/assets/home/default_avatar.png')
                "
              />
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-affix>

    <login_
      v-if="isLoginVisible"
      @closeLogin="isLoginVisible = false"
      @loginSucceed="loginSucceed"
    />
  </div>
</template>

<script>
import login_ from "@/components/login/index.vue"; // 登录

import { ElMessage } from "element-plus";

import ClipboardJS from "clipboard";

export default {
  data() {
    return {
      isLoginVisible: false,
      isLogin: localStorage.getItem("yx_isLogin") || false,
      userInfo: JSON.parse(localStorage.getItem("yx_userInfo")) || null,
      isLucency: true, // 默认 背景 透明
      menuList: [
        {
          name: "首页",
          index: "/",
          eventName: "click_wb_home_page",
        },
        {
          name: "关于我们",
          index: "/aboutUs",
          eventName: "click_wb_about_me",
        },
        {
          name: "加入我们",
          index: "/joinUs",
          eventName: "click_wb_join_me",
        },
        {
          name: "益学研究苑",
          index: "/yxStudy",
          eventName: "click_wb_research_garden",
        },
        {
          name: "产品中心",
          index: "/product",
          eventName: "click_wb_product_center",
        },
        {
          name: "会员中心",
          index: "/vip",
          eventName: "click_wb_vip_center",
        },
      ],

      clipboard: null,
    };
  },
  components: {
    login_,
  },
  props: {
    Lucency: {
      type: Boolean,
      default: true, // 为false时，背景 不透明
    },
    isWhite: {
      type: Boolean,
      default: false, // 为true时，背景 白色
    },
    isBlack: {
      type: Boolean,
      default: false, // 为true时，背景 黑色
    },
    isTop: {
      type: Boolean,
      default: true, // 为true时，直接 浮动置顶
    },
    activeIndex: {
      type: String,
      default: "/",
    },
  },
  created() {
    this.isLucency = this.Lucency;
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 监听滚动条
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      // console.log(scrollTop);
      if (scrollTop > 60) {
        this.isLucency = false;
      } else {
        if (!this.Lucency) {
          this.isLucency = false;
          return;
        }

        this.isLucency = true;
      }
    },

    // 导航 切换
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);

      const matchedItem = this.menuList.find((item) => item.index === key);
      if (matchedItem) {
        let eventName = matchedItem.eventName;
        // console.log(eventName);
        this.COMMON.zhuGeMaidian(eventName); // 诸葛 埋点
      }
      this.COMMON.zhuGeMaidian("pv"); // 诸葛 埋点
      this.COMMON.zhuGeMaidian("in_web"); // 诸葛 埋点

      this.$router.push({ path: key });
      // window.open(key);
    },

    // logo click
    logoClick() {
      this.$router.push({ path: "/" });
      // window.open('/');
    },

    // click 弹 登录框
    loginClick() {
      // 如果是 会员中心-点登录，禁止弹框
      if (this.$route.path == "/vip") return;

      this.COMMON.zhuGeMaidian("click_pc_login_button"); // 诸葛 埋点

      this.isLoginVisible = true; // 显示 登录框
    },

    // 登录成功
    loginSucceed() {
      this.isLogin = localStorage.getItem("yx_isLogin") || false;
      this.userInfo = JSON.parse(localStorage.getItem("yx_userInfo")) || null;

      this.isLoginVisible = false;
    },

    // 登录后，鼠标到头像上 显示 Tooltip
    handleTooltipShow() {
      // console.log("Tooltip 显示出来了");
      this.COMMON.zhuGeMaidian("click_profile_picture"); // 诸葛 埋点
    },

    // 登出
    logoutClick() {
      localStorage.removeItem("yx_isLogin");
      localStorage.removeItem("yx_userInfo");

      this.Cookies.remove("pc_advanced_lessons_user_id");

      this.isLogin = false;
      this.userInfo = null;

      // 如果是 会员中心、我的订单 刷新页面，个人中心 去首页
      switch (this.$route.path) {
        case "/vip":
        case "/order":
          location.reload();
          break;
        case "/home":
          this.$router.push("/");
          break;
      }
    },

    // copy yx号 click
    copyClick() {
      this.clipboard = new ClipboardJS(".copy_icon");
      this.clipboard.on("success", (e) => {
        ElMessage({
          message: "ID已复制到剪贴板",
          type: "success",
        });

        this.COMMON.zhuGeMaidian("copy_id"); // 诸葛 埋点

        this.clipboard.destroy();
        this.clipboard = null;
      });
      this.clipboard.on("error", (e) => {
        ElMessage.error("ID复制失败");
      });
    },

    // 登录 显示 菜单 click
    menuClick(type) {
      switch (type) {
        case 1:
          this.COMMON.zhuGeMaidian("click_account"); // 诸葛 埋点

          this.$router.push("/home");
          break;

        case 2:
          this.COMMON.zhuGeMaidian("click_my_order"); // 诸葛 埋点

          this.COMMON.routerSkip("/order");
          break;

        case 3:
          this.COMMON.zhuGeMaidian("click_advanced_class"); // 诸葛 埋点

          window.open(
            "//www.yxcps.com/outside/advanced-lessons/advanced-lessons-list.do"
          );
          break;
      }
    },
  },
};
</script>

<style lang="less">
.is-customized {
  background: #2a2a2c;
  width: 360px;
  padding: 0 !important;
  border-radius: 10px !important;

  .login_content {
    color: #fff;

    .msg_box {
      padding: 14px 16px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #3b3b3b;

      .info_box {
        display: flex;

        .photo_info {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }

        .info {
          padding-left: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .yx {
            display: flex;
            align-items: center;

            img {
              width: 14px;
              height: 14px;
              margin-left: 8px;
              cursor: pointer;
            }
          }
        }
      }

      .logout {
        span {
          cursor: pointer;
          transition: color 0.3s ease;

          &:hover {
            color: #5dacff;
          }
        }
      }
    }

    .menu_box {
      padding: 20px 16px;
      display: flex;
      justify-content: space-between;

      .menu {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: color 0.3s ease;

        &:hover {
          color: #5dacff;
        }

        img {
          width: 14px;
          height: 14px;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>

<style lang="less" scoped>
.nav_wrap {
  &.nav_wrap_isTop {
    position: fixed;
    top: 0;
    z-index: 200;
  }

  /deep/ .el-affix--fixed {
    z-index: 200 !important;
  }

  .menu_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.menu_box_bg_black {
      background-color: #000;
      // background-color: rgba(0, 0, 0, 0.5);
    }

    &.menu_box_bg_white {
      background-color: #fff;
    }

    &.menu_box_bg_transparent {
      background-color: transparent;
    }

    .left_box {
      width: calc(100vw - 132px);
      padding-left: 100px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo_box {
        width: 280px;
        height: 60px;
        cursor: pointer;

        &.logo_box_black {
          background: url("@/assets/navbar/logo_white.png") no-repeat;
          background-size: 100%;
          background-position: center center;
        }

        &.logo_box_white {
          background: url("@/assets/navbar/logo_black.png") no-repeat;
          background-size: 100%;
          background-position: center center;
        }

        &.logo_box_transparent {
          background: url("@/assets/navbar/logo_white.png") no-repeat;
          background-size: 100%;
          background-position: center center;
        }
      }

      .el_menu_demo {
        border-bottom: 0;
        align-items: center;

        .el-menu-item {
          display: inline-block;
          height: 36px;
          line-height: 36px;

          &:hover {
            background-color: transparent !important;
          }

          &.is-active {
            padding: 0;
            margin: 0 20px;

            &:focus {
              background-color: transparent !important;
            }
          }
        }

        &.el_menu_demo_black {
          background-color: #000;
          // background-color: transparent !important;

          .el-menu-item {
            color: #fff;

            &:hover {
              color: #337ecc;
            }

            &.is-active {
              color: #fff !important;
              background-color: transparent;
              // border-bottom-color: #fff !important;
              border-image: linear-gradient(92deg, #2cdbff 0%, #1285ff 100%) 1;
            }
          }
        }

        &.el_menu_demo_white {
          background-color: #fff;

          .el-menu-item {
            color: #000;

            &:hover {
              color: #337ecc;
            }

            &.is-active {
              color: #000 !important;
              background-color: transparent;
              // border-bottom-color: #fff !important;
              border-image: linear-gradient(92deg, #2cdbff 0%, #1285ff 100%) 1;
            }
          }
        }

        &.el_menu_demo_transparent {
          background-color: transparent;

          .el-menu-item {
            color: #fff;

            &:hover {
              color: #337ecc;
            }

            &.is-active {
              color: #fff !important;
              background-color: transparent;
              // border-bottom-color: #fff !important;
              border-image: linear-gradient(92deg, #2cdbff 0%, #1285ff 100%) 1;
            }
          }
        }
      }
    }

    .right_login {
      width: 132px;
      height: 60px;
      padding-left: 20px;
      font-size: 14px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      // justify-content: flex-end;
      cursor: pointer;
      transition: border-color 0.3s, background-color 03s, color 0.3s;

      &.right_login_black {
        color: #fff;
      }

      &.right_login_white {
        color: #000;
      }

      &.right_login_transparent {
        color: #fff;
      }

      .login_text {
        height: 36px;
        line-height: 36px;

        &:hover {
          color: #337ecc;
        }
      }

      .login_photo {
        .photo {
          width: 26px;
          height: 26px;
          display: block;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>