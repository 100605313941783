<template>
  <div class="vip_center_wrap">
    <navbar_ :Lucency="false" :isBlack="true" :activeIndex="'/vip'" />

    <div class="vip_container">
      <div class="product_box" v-if="isPageShow == 1">
        <div
          class="product_list"
          v-for="(item, index) in productList"
          :key="index"
        >
          <div class="left_box">
            <img :src="item.smallImg" class="smallImg" />

            <div class="info_box">
              <div class="title">{{ item.title }}</div>

              <div class="sub_title">{{ item.subTitle }}</div>

              <div class="expire_time">{{ item.expireTime }}</div>
            </div>
          </div>

          <div class="right_box">
            <div class="btn" @click="productClick(item)">使用</div>
          </div>
        </div>
      </div>

      <div class="product_info" v-if="isPageShow == 2">
        <img src="@/assets/vip_center/1.png" />
        <img src="@/assets/vip_center/2.png" />
        <img src="@/assets/vip_center/3.png" />
        <img src="@/assets/vip_center/4.png" />
        <img src="@/assets/vip_center/5.png" />
        <img src="@/assets/vip_center/6.png" />
      </div>
    </div>

    <footer_ />

    <login_ v-if="isLoginVisible" :isCloseShow="false" source="hy" />
  </div>
</template>

<script>
import navbar_ from "@/components/navbar/index.vue"; // 顶部导航
import footer_ from "@/components/footer/index.vue"; // 底部
import login_ from "@/components/login/index.vue"; // 登录

export default {
  data() {
    return {
      isLoginVisible: false,
      isLogin: localStorage.getItem("yx_isLogin") || false,
      userInfo: JSON.parse(localStorage.getItem("yx_userInfo")) || null,

      productList: [],
      isPageShow: 0,
    };
  },
  components: {
    navbar_,
    footer_,
    login_,
  },
  created() {
    if (this.isLogin) {
      this.getProductList(); // 获取 已购产品
      return;
    }

    this.isLoginVisible = true; // 显示 登录框
  },
  mounted() {},
  methods: {
    // 获取 已购产品
    getProductList() {
      let data = {
        userNo: this.userInfo.userNo,
      };

      this.API.CLOUDGet("stock-common/api/sells/sells_list", data).then(
        (res) => {
          // console.log(res);
          let datas = res.data.data;
          // console.log(datas);

          let list = datas.find((item) => item.categoryName === "已购服务");

          this.productList = list.list.filter((item) => item.pcredirect);

          // console.log(this.productList);

          if (this.productList.length > 0) {
            this.isPageShow = 1;

            this.getProductToken(); // 获取 产品 token
          } else {
            this.isPageShow = 2;
          }
        }
      );
    },

    // 获取 产品 token
    getProductToken() {
      let data = {
        userNo: this.userInfo.userNo,
      };

      this.API.WWWYXCPSGet("usercenter/buyedServicesTokenGen2", data).then(
        (res) => {
          // console.log(res);

          if (res.data.status == 1) {
            this.Cookies.set("pc_buyed_services_token", res.data.result, {
              expires: 1,
              path: "/",
              domain: ".yxcps.com",
              sameSite: "Strict",
            });
          }
        }
      );
    },

    // 产品 使用
    productClick(item) {
      // console.log(item);

      this.COMMON.zhuGeMaidian("click_wb_inter", {
        goods_name: item.title,
        goods_id: item.sellsId,
        source: "web",
      }); // 诸葛 埋点

      window.open(item.pcredirect);
    },

    // url 添加 时间戳
    addTimestampToUrl(url) {
      // 获取当前时间戳
      const timestamp = new Date().getTime();

      // 判断URL中是否包含?
      if (url.includes("?")) {
        // 如果包含?，则添加&t=时间戳
        return `${url}&t=${timestamp}`;
      } else {
        // 如果不包含?，则添加?t=时间戳
        return `${url}?t=${timestamp}`;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.vip_center_wrap {
  box-sizing: border-box;
  padding-top: 60px;

  .vip_container {
    min-height: calc(100vh - 60px);
    box-sizing: border-box;
    background: url("@/assets/vip_center/bg.png") no-repeat #000;
    background-size: 100%;
    color: #fff;

    .product_box {
      width: 980px;
      padding: 36px 0;
      margin: 0 auto;

      .product_list {
        margin-top: 24px;

        display: flex;
        justify-content: space-between;

        &:first-child {
          margin-top: 0;
        }

        .left_box {
          display: flex;

          .smallImg {
            width: 150px;
            height: 150px;
            border-radius: 10px;
          }

          .info_box {
            margin-left: 16px;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title {
              font-size: 18px;
              font-weight: 700;
            }
          }
        }

        .right_box {
          display: flex;
          align-items: center;

          .btn {
            width: 100px;
            height: 42px;
            line-height: 42px;
            font-size: 16px;
            text-align: center;
            background: linear-gradient(92deg, #2cdbff 0%, #1285ff 100%);
            letter-spacing: 2px;
            border-radius: 6px;
            cursor: pointer;
          }
        }
      }
    }

    .product_info {
      img {
        width: 100%;
        display: block;
      }
    }
  }
}
</style>
