import router from '../router'

let appId = "wx90f6fc3945f822e7" // 公众号 益学会员

let page_url = location.protocol + "//" + location.host;

let YX_IMG_URL; // 益学 图片 地址
let YX_CMS_API_URL; // 益学 cms 服务 域名
let YX_CMS_IMG_URL; // 益学 cms 图片 地址
let YX_CLOUD_API_URL; // 益学云 服务 域名
let YX_QUAN_API_URL; // quan 服务 域名
let YX_SZ_API_URL; // sz 服务 域名
let YX_STOCK_API_URL; // stock 服务 域名
let YX_WWWYXCPS_API_URL; // 测试 服务 域名

// let releaseType = 0; // 本地环境
// let releaseType = 1; // 测试环境
let releaseType = 2; // 正式环境

switch (releaseType) {
    case 0:
        YX_CMS_API_URL = 'http://172.21.3.153:8888/'
        YX_CMS_IMG_URL = 'http://172.21.3.153/'

        // YX_CLOUD_API_URL = 'http://172.21.3.164:9080/';
        YX_CLOUD_API_URL = 'http://172.21.3.202:8080/';
        YX_QUAN_API_URL = window.location.protocol + '//quanapi.yxcps.cn/'
        YX_SZ_API_URL = '//sz.yxcps.cn/'
        YX_WWWYXCPS_API_URL = 'http://172.20.10.14:8083/';
        break;
    case 1:
        YX_CMS_API_URL = 'http://172.21.3.153:8888/'
        YX_CMS_IMG_URL = 'http://172.21.3.153/'

        YX_CLOUD_API_URL = window.location.protocol + '//yxcloudapi.yxcps.cn/';
        YX_QUAN_API_URL = window.location.protocol + '//quanapi.yxcps.cn/'
        YX_SZ_API_URL = '//sz.yxcps.cn/'
        YX_STOCK_API_URL = window.location.protocol + '//stockapi.yxcps.cn/'
        break;
    case 2:
        YX_CMS_API_URL = window.location.protocol + '//yxtcmsapi.yxcps.cn/'
        YX_CMS_IMG_URL = window.location.protocol + '//yxtcmsweb.yxcps.cn/'

        YX_CLOUD_API_URL = window.location.protocol + '//yxcloudapi.yxcps.cn/';
        YX_QUAN_API_URL = window.location.protocol + '//quanapi.yxcps.cn/'
        YX_SZ_API_URL = '//sz.yxcps.cn/'
        YX_STOCK_API_URL = window.location.protocol + '//stockapi.yxcps.cn/'
        YX_IMG_URL = window.location.protocol + '//sf.yxcps.cn/'
        YX_WWWYXCPS_API_URL = '//www.yxcps.com/';
        break;
}

// 判断  ios/安卓
var u = navigator.userAgent;
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // android终端
var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
var isPC = !/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent); // pc端

// 诸葛 实名
function zhuGeIdentify() {
    let userInfo = JSON.parse(localStorage.getItem("yx_userInfo")) || null;
    // console.log(userInfo);

    if (!userInfo) {
        return;
    }

    window.zhuge.identify("yx" + userInfo.userNo, {
        platform_name: "官网",
    });
}

// 诸葛 埋点 对象展开
function zhuGeMaidian(eventName, jsonVal) {
    let isLogin = localStorage.getItem("yx_isLogin") || false;
    let userInfo = JSON.parse(localStorage.getItem("yx_userInfo")) || null;
    // console.log(userInfo);

    let josn = {
        platform_name: '官网',
        yx_id: userInfo ? userInfo.userNo : '',
        state: isLogin ? 1 : 2,

        ...(jsonVal || {}),
    };

    // console.log(eventName);
    // console.log(josn);
    window.zhuge.track(eventName, josn);
}

// 日期字符串（如：2020-05-02） 换算为  星期几
function getWeek(time, type) {
    switch (type) {
        case 1:
            time = getTimes(time, 2)
            break;
    }

    let dateArray = time.split("-");
    let date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
    return "周" + "日一二三四五六".charAt(date.getDay());
}

// 数字 小于10 数字前+0
function formatTime(num) {
    return (num < 10) ? '0' + num : num;
}

// 时间转换
function getTimes(mss, type) {
    let time;
    try {
        time = new Date(mss.replace(/-/g, '/'))
    } catch (error) {
        time = new Date(mss)
    }

    let year = time.getFullYear()
    const month = (time.getMonth() + 1).toString().padStart(2, '0')
    const date = (time.getDate()).toString().padStart(2, '0')
    const hours = (time.getHours()).toString().padStart(2, '0')
    const minute = (time.getMinutes()).toString().padStart(2, '0')
    const second = (time.getSeconds()).toString().padStart(2, '0')

    let text = '';
    switch (type) {
        case 1:
            text = year + '-' + month + "-" + date + ' ' + hours + ':' + minute + ':' + second;
            break;
        case 2:
            text = year + '-' + month + "-" + date;
            break;
        case 3:
            text = hours + ':' + minute;
            break;
        case 4:
            text = month + "-" + date + ' ' + hours + ':' + minute;
            break;
        case 5:
            text = month + "/" + date;
            break;
        case 6:
            text = year;
            break;
        case 7:
            text = year + '年' + month + "月" + date + "日";
            break;
        case 8:
            text = month + "-" + date;
            break;
        case 9:
            text = month + "/" + date + ' ' + hours + ':' + minute;
            break;
        case 10:
            text = year + '-' + month + "-" + date + ' ' + hours + ':' + minute;
            break;
        case 11:
            if (new Date(mss).toDateString() === new Date().toDateString()) {
                // console.log('今日');
                text = '今日 ' + hours + ':' + minute;
            } else {
                text = month + "-" + date + ' ' + hours + ':' + minute;
            }
            break;
        case 12:
            text = year + '.' + month + "." + date + '（' + getWeek(mss, 1) + '）' + hours + ':' + minute;
            break;
        case 13:
            text = year + '.' + month + "." + date + ' ' + hours + ':' + minute;
            break;
        case 14:
            text = date + '日';
            break;
        case 15:
            text = month + '月' + date + '日';
            break;
        case 16:
            text = hours + ':' + minute + ':' + second;
            break;
        case 17:
            text = month + '月' + date + '日' + ' ' + hours + ':' + minute;
            break;
        case 18:
            text = year + '/' + month + "/" + date + ' ' + hours + ':' + minute + ':' + second;
            break;
        case 21:
            text = year + '-' + month + "-" + date;
            break;
        default:
            text = year + '-' + month + "-" + date + ' ' + hours + ':' + minute + ':' + second;
            break;
    }
    return text;
}

// 保留几位小数（四舍五入）
function fomatFloat(src, pos) {
    return Math.round(src * Math.pow(10, pos)) / Math.pow(10, pos);
}

// 保留两位小数（不四舍五入）
function formatDecimal(num, decimal) {
    num = num.toString()
    let index = num.indexOf('.')
    if (index !== -1) {
        num = num.substring(0, decimal + index + 1)
    } else {
        num = num.substring(0)
    }
    // return Number(parseFloat(num).toFixed(decimal))

    let value = Number(parseFloat(num).toFixed(decimal))

    return returnFloat(value)
}

// 两位小数 不足自动补零
function returnFloat(value) {
    var s = value.toString().split(".");
    if (s.length == 1) {
        value = value.toString() + ".00";
        return value;
    }
    if (s.length > 1) {
        if (s[1].length < 2) {
            value = value.toString() + "0";
        }
        return value;
    }
}

// 获取URL参数
function getUrlParams(url) {
    try {
        // 通过 ? 分割获取后面的参数字符串
        let urlStr = url.split('?')[1]
        // 创建空对象存储参数
        let obj = {};
        // 再通过 & 将每一个参数单独分割出来
        let paramsArr = urlStr.split('&')
        for (let i = 0, len = paramsArr.length; i < len; i++) {
            // 再通过 = 将每一个参数分割为 key:value 的形式
            let arr = paramsArr[i].split('=')
            obj[arr[0]] = arr[1];
        }
        return obj
    } catch (error) {
        return {}
    }
}

// 新开页 跳转
function routerSkip(path, query) {
    let toQuery = {
        ...query,
    }

    let href = router.resolve({
        path: path,
        query: toQuery,
    });

    window.open(href.href);
}

export default {
    appId,
    page_url,
    isAndroid,
    isIOS,
    isPC,
    YX_CLOUD_API_URL,
    YX_QUAN_API_URL,
    YX_CMS_API_URL,
    YX_CMS_IMG_URL,
    YX_SZ_API_URL,
    YX_STOCK_API_URL,
    YX_IMG_URL,
    YX_WWWYXCPS_API_URL,
    zhuGeIdentify,
    zhuGeMaidian,
    getWeek,
    getTimes,
    formatTime,
    fomatFloat,
    formatDecimal,
    getUrlParams,
    routerSkip,
}