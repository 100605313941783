<template>
  <div class="screen_wrap">
    <el-carousel
      ref="carousel"
      trigger="click"
      :height="screenHeight + 'px'"
      :interval="10000"
      :indicator-position="advList.length == 0 ? 'none' : ''"
      arrow="never"
      @mousedown="handleMousedown"
      @mousemove="handleMousemove"
      @mouseup="handleMouseup"
    >
      <el-carousel-item>
        <div class="screen_box">
          <video
            ref="myVideo"
            class="myVideo"
            autoplay
            muted
            poster="//stocksf.yxcps.com/cc/media/yxt_web_bg.png?t=1"
            src="//stocksf.yxcps.com/cc/media/yxt_web.mp4?t=1"
            @ended="handleEnded"
          ></video>

          <div class="text_box">
            <div
              class="text"
              :class="item.type1"
              v-for="(item, index) in screen_text"
              :key="index"
            >
              <div
                v-for="(char, index1) in item.textArr"
                :key="index1"
                :class="item.type2"
                :style="{
                  animationDelay: (1 + char.delay).toFixed(2) + 's',
                }"
              >
                {{ char.text }}
              </div>
            </div>

            <div class="next_box">
              <div class="liaojie" @click="arrowsClick()">了解更多</div>
            </div>
          </div>

          <!-- <div class="arrows_box" @click="arrowsClick()">
            <img src="@/assets/index/screen/arrows.png" />
            <img src="@/assets/index/screen/arrows.png" />
            <img src="@/assets/index/screen/arrows.png" />
          </div> -->
        </div>
      </el-carousel-item>

      <el-carousel-item v-for="(item, index) in advList" :key="index">
        <img
          :src="item.bannerUrl"
          draggable="false"
          :class="{ handImg: item.bannerRelationUrl }"
          @click="advClick(item)"
        />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      videoTime: 2.1, // 从第 n 秒 开始播放视频
      advList: [],
      screen_text: [
        {
          text: "上海益学智迅科技发展有限公司",
          textArr: [],
          type1: "text1",
          type2: "char char1",
        },
        {
          text: "在线学炒股 就上益学堂",
          textArr: [],
          type1: "text2",
          type2: "char char2",
        },
      ],

      dragging: false,
      initialX: 0,
      initialY: 0,
      currentX: 0,
      currentY: 0,
      threshold: 50, // 触发切换的最小拖拽距离

      isClick: true, // 假设是点击事件
    };
  },
  props: {
    screenHeight: window.innerHeight,
  },
  created() {
    // console.log(this.screenHeight);
  },
  mounted() {
    for (let i = 0; i < this.screen_text.length; i++) {
      this.screen_text[i].textArr = this.screen_text[i].text.split("");

      this.screen_text[i].textArr = this.screen_text[i].textArr.map(
        (char, idx) => {
          return {
            text: char,
            delay:
              i > 0
                ? this.screen_text[i - 1].textArr[
                    this.screen_text[i - 1].textArr.length - 1
                  ].delay +
                  0.04 +
                  idx * 0.04
                : idx * 0.04,
          };
        }
      );
    }
    // console.log(this.screen_text);

    this.getAdvList(); // 获取 banner list
  },
  methods: {
    // 监听视频播放结束事件
    handleEnded() {
      // 从第2秒开始播放
      this.$refs.myVideo.currentTime = this.videoTime;
      this.$refs.myVideo.play(); // 重新播放
    },

    // 轮播图 鼠标触摸 左/右滑动
    handleMousedown(event) {
      this.dragging = true;
      this.initialX = event.clientX;
      this.initialY = event.clientY;
      this.isClick = true;
    },
    handleMousemove(event) {
      if (!this.dragging) return;

      this.currentX = event.clientX;
      this.currentY = event.clientY;
      const deltaX = this.currentX - this.initialX;
      const deltaY = this.currentY - this.initialY;

      // 判断是否是滑动操作
      if (
        Math.abs(deltaX) > this.threshold ||
        Math.abs(deltaY) > this.threshold
      ) {
        this.isClick = false; // 不是点击事件，是滑动操作
      }

      const carousel = this.$refs.carousel;
      if (deltaX > this.threshold) {
        carousel.prev();
        this.dragging = false;
      } else if (-deltaX > this.threshold) {
        carousel.next();
        this.dragging = false;
      }
    },
    handleMouseup() {
      this.dragging = false;
    },

    // 向下箭头 click
    arrowsClick() {
      this.$emit("carousel_next");
    },

    // 获取 banner list
    getAdvList() {
      let data = {
        agencyId: "www.yxcps.com",
        // agencyId: "www.devyxcps.cn",
        bannerJsp: 1,
      };

      this.API.CLOUDGet("cms/api/website/banner/list", data).then((res) => {
        // console.log(res);
        let datas = res.data.data;
        // console.log(datas);

        if (datas) {
          this.advList = datas.map((obj) => {
            if (obj.bannerUrl && !obj.bannerUrl.includes("http")) {
              obj.bannerUrl = `//sf.yxcps.com/${obj.bannerUrl}`;
            }
            return obj;
          });
        }
      });
    },

    // adv click
    advClick(item) {
      let urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

      if (item.bannerRelationUrl && urlPattern.test(item.bannerRelationUrl)) {
        if (this.isClick) {
          this.COMMON.zhuGeMaidian("in_web", {
            web_page_link: item.bannerRelationUrl,
          }); // 诸葛 埋点

          window.open(item.bannerRelationUrl);
        }
      }
    },
  },
};
</script>
  
<style lang="less" scoped>
.screen_wrap {
  .myVideo,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .screen_box {
    height: 100%;
    position: relative;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    .text_box {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        letter-spacing: 10px;

        &.text1 {
          font-size: 55px;
          font-weight: bold;
        }

        &.text2 {
          margin-top: 27px;
          font-size: 40px;
          font-weight: 400;
        }

        .char {
          display: inline-block;
          opacity: 0;

          &.char1 {
            transform: translateY(30px);
            animation: slideIn1 0.5s forwards;
          }

          &.char2 {
            // transform: translateX(-100%);
            transform: translateX(0);
            animation: slideIn2 0.5s forwards;

            &:nth-child(6) {
              margin-left: 24px;
            }
          }
        }
      }

      .next_box {
        margin-top: 36px;
        color: #fff;

        .liaojie {
          margin: 0 auto;
          width: 120px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          font-size: 14px;
          border-radius: 30px;
          border: 1px solid #c0dcff;
          cursor: pointer;

          opacity: 0;
          animation: fadeIn 1s linear forwards;
          animation-delay: 1.7s;

          @keyframes fadeIn {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }
      }
    }

    .arrows_box {
      width: 24px;
      height: 45px;
      cursor: pointer;
      position: absolute;
      left: 50%;
      bottom: 60px;
      transform: translateX(-50%);

      opacity: 0;
      animation: appear 0.5s ease forwards;
      animation-delay: 1s;

      img {
        width: 24px;
        height: 15px;

        &:nth-child(1) {
          animation: moveDown1 1.5s ease-in-out infinite;
          animation-delay: 1s;
        }
        &:nth-child(2) {
          animation: moveDown2 1.5s ease-in-out infinite;
          animation-delay: 1s;
        }
        &:nth-child(3) {
          animation: moveDown3 1.5s ease-in-out infinite;
          animation-delay: 1s;
        }
      }

      @keyframes appear {
        to {
          opacity: 1;
        }
      }
      @keyframes moveDown1 {
        0%,
        100% {
          opacity: 0;
        }
        20% {
          opacity: 0.5;
        }
        40% {
          opacity: 1;
        }
        60% {
          opacity: 1;
        }
        80% {
          opacity: 1;
        }
      }
      @keyframes moveDown2 {
        0%,
        100% {
          opacity: 0;
        }
        20% {
          opacity: 0;
        }
        40% {
          opacity: 0.5;
        }
        60% {
          opacity: 1;
        }
        80% {
          opacity: 1;
        }
      }
      @keyframes moveDown3 {
        0%,
        100% {
          opacity: 0;
        }
        20% {
          opacity: 0;
        }
        40% {
          opacity: 0;
        }
        60% {
          opacity: 0.5;
        }
        80% {
          opacity: 1;
        }
      }
    }
  }

  .handImg {
    cursor: pointer;
  }
}

@keyframes slideIn1 {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn2 {
  0% {
    opacity: 0;
    // transform: translateX(-100%);
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>